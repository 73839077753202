import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Button, InputBase, useScrollTrigger, Slide, Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import { Search, Menu as MenuIcon, ArrowDropDown } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import  LOGO_URL from './assets/wsi-logo.jpg';
//import HeroSection from './components/hero';
import Content1 from './pages/tartalom1';
import PartnerFeedback from './pages/tartalom2';
import Content3 from './pages/tartalom3';
import FullWidthImage from './pages/tartalom4';
import OurTeam from './pages/tartalom5';
import Partners from './pages/tartalom6';
import ContactForm from './pages/tartalom7';
import Hero from './pages/tartalom0';
//import  { HeroSection } from './components/hero'
//import HERO_URL from './assets/hero.png' 


function HideOnScroll(props) {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  );
}

export default function Header(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenuClick = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setActiveMenu(menu);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setActiveMenu(null);
  };

  const handleSearchToggle = () => {
    setSearchOpen((prev) => !prev);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const renderMenuButtons = () => (
    <>
      {/* Szolgáltatások Menü */}
      <Button
        aria-controls="services-menu"
        aria-haspopup="true"
        onClick={(e) => handleMenuClick(e, 'services')}
        sx={{
          color: 'brown',
          '&:hover': { color: 'darkbrown', backgroundColor: 'transparent'},
        }}
        endIcon={<ArrowDropDown />}
      >
        Szolgáltatások
      </Button>
      <Menu
        id="services-menu"
        anchorEl={anchorEl}
        keepMounted
        open={activeMenu === 'services'}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>Ügyfélszolgálat</MenuItem>
        <MenuItem onClick={handleMenuClose}>Könyvelés</MenuItem>
        <MenuItem onClick={handleMenuClose}>Logisztika</MenuItem>
      </Menu>

      {/* Referenciák Menü */}
      <Button
        aria-controls="references-menu"
        aria-haspopup="true"
        onClick={(e) => handleMenuClick(e, 'references')}
        sx={{
          color: 'brown',
          '&:hover': { color: 'darkbrown', backgroundColor: 'transparent' },
        }}
        
      >
        Referenciák
      </Button>

      {/* Kapcsolat Menü */}
      <Button
        aria-controls="contact-menu"
        aria-haspopup="true"
        onClick={(e) => handleMenuClick(e, 'contact')}
        sx={{
          color: 'brown',
          '&:hover': { color: 'darkbrown', backgroundColor: 'transparent'},
        }}
        
      >
        Kapcsolat
      </Button>
      <Menu
        id="contact-menu"
        anchorEl={anchorEl}
        keepMounted
        open={activeMenu === 'contact'}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>Kapcsolat</MenuItem>
        <MenuItem onClick={handleMenuClose}>Adatvédelmi nyilatkozat</MenuItem>
        <MenuItem onClick={handleMenuClose}>ÁSZF</MenuItem>
      </Menu>

      {/* Dolgozz velünk Menü */}
      <Button
        aria-controls="work-menu"
        aria-haspopup="true"
        onClick={(e) => handleMenuClick(e, 'work')}
        sx={{
          color: 'brown',
          '&:hover': { color: 'darkbrown', backgroundColor: 'transparent' },
        }}
        endIcon={<ArrowDropDown />}
      >
        Dolgozz velünk
      </Button>
      <Menu
        id="work-menu"
        anchorEl={anchorEl}
        keepMounted
        open={activeMenu === 'work'}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>Raktáros</MenuItem>
        <MenuItem onClick={handleMenuClose}>Csomagoló</MenuItem>
        <MenuItem onClick={handleMenuClose}>Könyvelő</MenuItem>
      </Menu>
    </>
  );

  return (
    <div>
    
    <HideOnScroll {...props}>
      <AppBar position="sticky" sx={{ backgroundColor: 'white', color: 'brown' }} >
        <Toolbar>
          {/* Logo */}
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
          </Typography>
          
          <Box sx={{ flexGrow: 1 }}>
            <img src= { LOGO_URL } alt="Logo" style={{ height: '80px', width: 'auto' }} />
          </Box>

          {/* Mobil nézet: hamburger menü */}
          {isMobile ? (
            <>
              <IconButton onClick={toggleDrawer(true)} sx={{ color: 'brown' }}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                  <List>
                    <ListItem button>
                      <ListItemText primary="Szolgáltatások" />
                      </ListItem>
                    <ListItem button>
                      <ListItemText secondary="- Ügyfélszolgálat" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText secondary="- Könyvelés" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText secondary="- Logisztika" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Referenciák" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Kapcsolat" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText secondary="- Kapcsolat" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText secondary="- Adatvédelmi nyilatkozat" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText secondary="- ÁSZF" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText primary="Dolgozz velünk" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText secondary="- Raktáros" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText secondary="- Csomagoló" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText secondary="- Könyvelő" />
                    </ListItem>
                  </List>
                </Box>
              </Drawer>
            </>
          ) : (
            renderMenuButtons()
          )}

          {/* Nagyító ikon */}
          <IconButton onClick={handleSearchToggle} sx={{ color: 'brown' }}>
            <Search />
          </IconButton>

          {/* Kereső mező (feltételes megjelenítés) */}
          {searchOpen && (
            <InputBase
              placeholder="Keresés..."
              sx={{ ml: 1, flexGrow: 1, color: 'black', border: '1px solid brown', padding: '2px 4px' }}
            />
          )}
        </Toolbar>
      </AppBar>

    </HideOnScroll>
    
    <Hero />
    <Content1 />
    <PartnerFeedback />
    <Content3 />
    <FullWidthImage />
    <OurTeam />
    <Partners />
    <PartnerFeedback />
    <ContactForm />
    </div>
  );

}
