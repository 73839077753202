import { Box } from '@mui/material';



const Hero = () =>{
  return (
<><Box
      component="img"
      src="http://webshopiroda.hu/fd45vg6/uploads/2019/01/webshopiroda-csomaglogisztika-csomagol%C3%A1s-web%C3%A1ruh%C3%A1zaknak-fulfillment-rakt%C3%A1roz%C3%A1s.png"
      sx={{
        width: '100%',
        height: 'auto',
        objectFit: 'cover'
      }}
    />
    <div style={{textAlign: 'center', padding: '40px'}}>
          <button
          style={{
            padding: '10px 20px',
            fontSize: '1.2rem',
            color: '#fff',
            backgroundColor: '#007BFF',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          onClick={() => alert('Gombra kattintva!')}
        >
          BŐVEBB INFÓT SZERETNÉK
        </button>
        </div>
        </>
  );
}

export default Hero;