import { Grid, Box, TextField, Button, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';


const ContactForm = () =>{


  return (
    <Grid container spacing={2} maxWidth={800} margin="auto" padding={'30px'}>
       <Grid item xs={12} sm={4} >
    <Typography variant="body2" align='center' padding={'10px'}><p></p>Hívásod mindig örömmel fogadjuk!<p></p><b> +36308483100</b><p></p>Vagy írj nekünk, ami számodra kényelmesebb:<p><b>logisztika@webshopiroda.hu</b></p></Typography><p></p>
    <Typography align='center'>
    <Button variant="outlined" startIcon={<FacebookIcon />}>Kövess minket!</Button>
    </Typography>
    </Grid>
    <Grid item xs={12} sm={8} >
    <Typography variant="h6">Üzenj nekünk!</Typography>
    <Box component="form" sx={{ maxWidth: 600, margin: 'auto' }}>
      <TextField fullWidth label="Név" margin="normal" size='small'/>
      <TextField fullWidth label="Email" margin="normal" size='small'/>
      <TextField fullWidth label="Üzenet" multiline rows={4} margin="normal" size='small'/>
      <Button variant="contained" type="submit">Küldés</Button>
    </Box>
    </Grid>

    </Grid>
  );
}

export default ContactForm;