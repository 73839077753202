import { Grid, Typography, Card, CardMedia, CardContent, } from '@mui/material';

const staffData = [
  { image: 'http://webshopiroda.hu/fd45vg6/uploads/2018/09/Dublinszki-L%C3%A1szl%C3%B3-Webshopiroda.png', name: 'Dublinszki László', title: 'Alapító, tulajdonos', comment: 'Akihez bármikor fordulhatsz kéréseiddel, kérdéseiddel.' },
  { image: 'http://webshopiroda.hu/fd45vg6/uploads/2018/09/Dublinszki-L%C3%A1szl%C3%B3-Webshopiroda.png', name: 'Dublinszki László', title: 'Alapító, tulajdonos', comment: 'Akihez bármikor fordulhatsz kéréseiddel, kérdéseiddel.' },
  { image: 'http://webshopiroda.hu/fd45vg6/uploads/2018/09/11.png', name: 'Havan Lilla', title: 'Marketing és operatív menedzser', comment: 'Aki a háttérben mozgatja szálakat: web, marketing, rendszerek.' }
];

const OurTeam = () =>{

  return (
   
    <Grid container spacing={2} maxWidth={800} margin="auto" padding={'50px'}>
      {staffData.map((staff, index) => (
         <>
              <Grid item xs={12} sm={3} key={index}>
            <Card>
            <CardMedia component="img" image={staff.image} alt={staff.name} />
            <CardContent>
            <Typography variant="body">{staff.name}</Typography>
            <Typography variant="body2" textAlign={'justify'}>{staff.title}</Typography>
            <p></p><Typography fontSize={8}>{staff.comment}</Typography>
            </CardContent>
        </Card>
      </Grid>
      </>
      ))}
    </Grid>
  );
}

export default OurTeam;