import { Box } from '@mui/material';



const FullWidthImage = () =>{
  return (
    <><Box
      component="img"
      src="http://webshopiroda.hu/fd45vg6/uploads/2018/08/sz%C3%A1mla-2F-sz%C3%A1ll%C3%ADt%C3%B3-beleheyez%C3%A9se.png"
      sx={{
        width: '100%',
        height: 'auto',
        objectFit: 'cover'
      }}
    />
    <Box
    component="img"
    src="http://webshopiroda.hu/fd45vg6/uploads/2018/08/Kik-vagyunk-mi_.png"
    sx={{
      width: '100%',
      height: 'auto',
      objectFit: 'cover'
    }}
  />
    </>
  );
}

export default FullWidthImage;