import { Box, Grid, Typography } from '@mui/material';
import { BsChatQuote } from "react-icons/bs";

const partnerData = {
  image: `https://webshopiroda.hu/fd45vg6/uploads/2018/08/WolfGabor2017-nagy-nyomdai.jpg`,
  quote: 'Amikor belefogtam az infomarketingbe és a termékértékesítésbe, az elején szinte belerokkantunk a logisztikába – a garázsunk tele volt dobozokkal, kiszállítási napokon a lakásban álltak a termékek és mi címkéztük őket. Néha persze elkevertük a számlákat – egy káosz volt az egész és egy csomó nyűg! Mióta a WebshopIroda átvette tőlünk a logisztikai feladatokat, azóta az infótermékek eladásával csak két fronton találkozom: kapok egy emailt miden megrendelésről és egy sms-t amikor a vevő fizet. A többit a WebshopIroda intézi profin, megbízhatóan – és én végre koncentrálhatok az üzletfejlesztésre!',
  name: 'Wolf Gábor',
  title: 'Marketing Commando'
};

const PartnerFeedback = () => {
  return (
    <Box sx={{ bgcolor: '#fff4cc', py: 4 }}>
      <Grid container spacing={2} maxWidth={800} margin="auto">
        <Grid item xs={12} sm={3}>
          <Box component="img" src="https://webshopiroda.hu/fd45vg6/uploads/2018/08/WolfGabor2017-nagy-nyomdai.jpg" alt={partnerData.name} sx={{ borderRadius: '450px', width: '100%', height: 'auto' }} />
        </Grid>
        <Grid item xs={12} sm={9}>
        <BsChatQuote color='brown' size={30}/>
          <Typography textAlign={'justify'} variant="body2">{partnerData.quote}</Typography>
          <Typography variant="h8"><b>{partnerData.name}</b></Typography>
          <p></p>
          <Typography variant="subtitle2">{partnerData.title}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PartnerFeedback;