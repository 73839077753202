import { Grid, Box } from '@mui/material';
import Divider from '@mui/material/Divider';


const partnerLogos = [
  'https://webshopiroda.hu/fd45vg6/uploads/2018/09/3-1.png',
  'https://sparitual.com/cdn/shop/t/25/assets/sparitual-logo.svg?v=123373000127985467441624575280',
  'http://webshopiroda.hu/fd45vg6/uploads/2018/09/4.png',

];
const Partners = () =>{

  return (
    <>
    <Divider>Partnereink</Divider>
    
    <Grid container spacing={2} maxWidth={800} margin="auto" padding={'30px'}>
      {partnerLogos.map((logo, index) => (
        <Grid item xs={6} sm={12} key={index}>
          <Box component="img" src={logo} alt={`Partner ${index}`} sx={{ width: '100%' }} />
        </Grid>
      ))}
    </Grid>
      <Divider/>
      </>
  );
}

export default Partners;